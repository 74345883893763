<template>
  <app-layout class="bg-background">
    <div class="m-auto">
      <div class="container">
        <p>{{ $route.name ?? '-' }}</p>
      </div>
    </div>
  </app-layout>
</template>

<script>
export default {};
</script>
